import { useState } from "react";
import { CloseButton } from "react-bootstrap";
import scan from "../../assets/img/svgs/scan.svg";
import useAuth from "../../hooks/useAuth";
import ScanVideo from "./ScanVideo";

// Allow user to scan qrcode with mobile device
const ScanCamera = () => {
  const [show, setShow] = useState(false);
  const { user } = useAuth();

  return (
    <>
      {(user?.showQrCodes || user?.role === 'Root') ? <div className="w-100 h-100 mobile-scan">
        <img alt={"scan-icon"} src={scan} style={{ width: "22px" }} className="me-2" onClick={() => setShow(true)} />
        {show && <div style={{ left: 0 }}
                      className="bg-light position-fixed w-100 h-100 top-0 z-50 d-flex justify-content-center align-items-center">
          <CloseButton onClick={() => setShow(false)} className="position-absolute"
                       style={{ right: "20px", top: "20px" }} />
          <ScanVideo onGet={() => setShow(false)} />
        </div>}
      </div> : null}
    </>
  );
};

export default ScanCamera;