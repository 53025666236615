import React, { useState } from "react";
import { Button, Nav, Tab } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TimelineTable from "./TimelineTable";
import Table from "./Table";
import DatetimePicker from "react-bootstrap-daterangepicker";
import moment from "moment-timezone";
import "bootstrap-daterangepicker/daterangepicker.css";
import ZoneCurrentTable from "./ZoneCurrentTable";
import * as queryString from "query-string";
import { File } from "react-feather";
import api from "../../api";
import { downloadFile } from "../../utils/staticMethods";
import ExportButton from "../ExportButton";

window.moment = moment

let emptyText = "There's no attendance history to show yet. Attendances are created when people or assets spend time within zones. This is done automatically when a GPS tracker moves through a 'Geofence' or when a tag is seen by a reader assigned to a zone.";

const Attendance = () => {
  const location = useLocation();
  const [curTab, setTab] = useState("first");
  const [filter, setFilter] = useState('');
  const { id, name, type, end } = queryString.parse(location.search);
  const [duration, setDuration] = useState(end? [moment(end).subtract(30, "day").startOf("days"), moment(end).endOf('days')] : []);
  const tableRef = React.createRef();
  const timeLineRef = React.createRef();
  const [total, setTotal] = useState(0);

  const onTabChange = (e) => {
    setTab(e);
  };

  const onApply = (start, end) => {
    setDuration([start, end]);
  };

  const searchFilter = () => {
      timeLineRef.current.handleSwitchPage(1);
      tableRef.current.handleSwitchPage(1);
  };
  const resetDuration = (startTime, endTime) => {
    // when table get data first time, it will auto set the default date range to the first record's time to last record's time
    if(!end) {
      setDuration([startTime, endTime]);
    }
  };

  const tableLoaded = (total) => {
    setTotal(total);
  };

  // export files according to date time
  const handleExport = () => {
    if (type === "assets" || type === "people") {
      api.get(`${type}/attendances/${id}/export?filter=${filter}&startDate=${duration[0].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}`, { responseType: "blob" }).then(res => {
        downloadFile(res);
      });
    } else if (type === "zone") {
      api.get(`zones/pastcontents/${id}/export?filter=${filter}&startDate=${duration[0].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}`, { responseType: "blob" }).then(res => downloadFile(res));
    } else if (type === "zoneCurrent") {
      api.get(`zones/livecontents/${id}/export?filter=${filter}`, { responseType: "blob" }).then(res => downloadFile(res));
    } else if (type === "assetsCurrent") {
      api.get(`assets/livecontents/${id}/export?filter=${filter}`, { responseType: "blob" }).then(res => downloadFile(res));
    } else if (type === "peopleCurrent") {
      api.get(`people/livecontents/${id}/export?filter=${filter}`, { responseType: "blob" }).then(res => downloadFile(res));
    } else if (type === "assetsPast") {
      api.get(`assets/pastcontents/${id}/export?filter=${filter}&startDate=${duration[0].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}`, { responseType: "blob" }).then(res => downloadFile(res));
    } else if (type === "peoplePast") {
      api.get(`people/pastcontents/${id}/export?filter=${filter}&startDate=${duration[0].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1].tz('Europe/London').format("YYYY-MM-DDTHH:mm")}`, { responseType: "blob" }).then(res => downloadFile(res));
    }
  };

  const getType = () => {
    if (type === "zoneCurrent") return "zones";
    if (type === "assetsCurrent") return "assets";
    if (type === "peopleCurrent") return "people";
  };

  return (
    <React.Fragment>
      <Helmet defer={false}  title={(type === "assets" || type === 'people') ? 'Attendance History' : 'Contents History' } />
      {(type === "assets" || type === 'people') && <h1 className="h3 mb-4">Attendance History - <NavLink
        to={{ pathname: `/assets/edit`, search: `?id=${id}` }}><span>{name}</span></NavLink></h1>}
      {type === "zone" && <h1 className="h3 mb-4">Contents History - <NavLink
        to={{ pathname: `/zones/edit`, search: `?id=${id}` }}><span>{name}</span></NavLink></h1>}
      {type === 'assetsPast' && <h1 className="h3 mb-4">Contents History - <NavLink
        to={{ pathname: `/assets/edit`, search: `?id=${id}` }}><span>{name}</span></NavLink></h1>}
      {type === 'peoplePast' && <h1 className="h3 mb-4">Contents History - <NavLink
        to={{ pathname: `/people/edit`, search: `?id=${id}` }}><span>{name}</span></NavLink></h1>}
      {(type === "zoneCurrent" || type === "assetsCurrent" || type === "peopleCurrent") &&
      <h1 className="h3 mb-4">Current Attendance - <NavLink
        to={{ pathname: `/${getType()}/edit`, search: `?id=${id}` }}><span>{name}</span></NavLink></h1>}
      {type !== "zoneCurrent" && type !== "assetsCurrent" && type !== "peopleCurrent" && <div className="d-flex">
        {duration.length > 0 && <DatetimePicker
          initialSettings={{
            timePicker: true,
            timePicker24Hour: true,
            startDate: duration[0],
            endDate: duration[1],
            locale: {
              format: "DD/MM/YYYY HH:mm"
            }
          }}
          onCallback={onApply}
        >
          <input type="text" className="form-control w-50" />
        </DatetimePicker>}
        <Button className="ms-2" onClick={() => searchFilter()}>Filter</Button>
      </div>}
      <div className="tab mt-4">
        <Tab.Container id="left-tabs-example"
                       defaultActiveKey={`${(type === "zoneCurrent" || type === "assetsCurrent" || type === "peopleCurrent") ? "second" : "first"}`}
                       onSelect={e => onTabChange(e)}>
          <Nav variant="tabs">
            {type !== "zoneCurrent" && type !== "assetsCurrent" && type !== "peopleCurrent" && <Nav.Item>
              <Nav.Link eventKey="first">Timeline</Nav.Link>
            </Nav.Item>}
            <Nav.Item>
              <Nav.Link eventKey="second">Table</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Export</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            {type !== "zoneCurrent" && type !== "assetsCurrent" && type !== "peopleCurrent" &&
            <Tab.Pane eventKey="first">
              <TimelineTable filterText={filter} onChangeFilter={(f) => setFilter(f)} emptyText={emptyText} tableLoaded={tableLoaded} resetDuration={resetDuration}
                             ref={timeLineRef} duration={duration} id={id} curTab={curTab}
                             type={type} />
            </Tab.Pane>}
            <Tab.Pane eventKey="second">
              {type !== "zoneCurrent" && type !== "assetsCurrent" && type !== "peopleCurrent" &&
              <Table filterText={filter} onChangeFilter={(f) => setFilter(f)} emptyText={emptyText} tableLoaded={tableLoaded} resetDuration={resetDuration} ref={tableRef}
                     id={id} duration={duration} curTab={curTab}
                     type={type} />}
              {(type === "zoneCurrent" || type === "assetsCurrent" || type === "peopleCurrent") &&
              <ZoneCurrentTable setCountParent={tableLoaded} id={id} curTab={curTab} type={type} />}
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              {Number(total) > 0 && <>
                <h4 className="tab-title"><File size={18} className="me-1" />Export</h4>
                <p>This will create a csv export file containing all data recorded between the selected dates.</p>
                <div>
                  <ExportButton title={"Export"} count={total} onClick={() => handleExport()} />
                </div>
              </>}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
};
export default Attendance;