import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import { getUrl } from "../utils/staticMethods";
import defaultImg from "../assets/img/icon/GPS/placeholder.png";
import api from "../api";

let timer = null
const SignalMonitoring = ({detail, tracker, asset}) => {

  const [loading, setLoading] = useState(false)
  const [curTracker, setCurTracker] = useState(tracker)

  const sendCommand = () => {
    api.post(`trackers/${tracker.id}/signalmonitoring/activate`).then(r => {
      setLoading(true)
      timer = setInterval(() => {
        getTracker()
      }, 5000)
    })
  }

  useEffect(() => {
    return() => clearInterval(timer)
  }, [])

  const getTracker = () => {
    api.get(`assets/trackers/${asset.id}`)
      .then(res => {
        if(res.data.signalMonitoringEnabled) clearInterval(timer)
        setCurTracker(res.data);
      }).catch(() => {
      setCurTracker({});
    });
  };

  return <React.Fragment>
    <Card className="mb-0">
      <Card.Body className={`${detail ? 'p-0' : ''} border-top`}>
        <ul className="list-unstyled col-sm-12">
          <li data-toggle="tooltip" title="" data-placement="right">
            <h6>LIVE SIGNAL MONITORING</h6>
          </li>
          <li className="d-flex justify-content-center align-items-center">
            {curTracker?.showSignalMonitoring && !curTracker.signalMonitoringEnabled && <div className="d-flex flex-column">
              <img alt="profile" style={{width: '80px'}} src={imageSet[getUrl(curTracker.assetIcon)] ? require("../assets/img/icon/" + imageSet[getUrl('trafficlight.png')]).default : defaultImg} />
              {loading ? <Button className="mt-3" disabled variant="secondary">Waiting...</Button> : <Button onClick={() => sendCommand()} className="mt-3" variant={'success'}>Activate</Button>}
            </div>}
            {curTracker?.signalMonitoringEnabled && <img alt="profile" style={{width: '80px'}} src={imageSet[getUrl(asset.icon)] ? require("../assets/img/icon/" + imageSet[getUrl(asset.icon)]).default : defaultImg} />}
          </li>
        </ul>
      </Card.Body>
    </Card>
  </React.Fragment>
}
export default SignalMonitoring