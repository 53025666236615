import { Card } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import { dateFromNow } from "../utils/staticMethods";
import { Fragment } from "react";

const LastStates = ({ profile, title, detail }) => {

  const states = profile.lastStates || [];

  return <Fragment>
      {states && states.map((item) => (
(item?.type === "urban64-fault1") &&
<Card key={item.id} className={`mb-0 ${(!title && !detail) ? 'border-top' : ''}`}>
<Card.Body className={detail ? 'p-0' : ''}>
  <ul className="list-unstyled col-sm-12">
    <li data-toggle="tooltip" title="" data-placement="right">
      <h6>CF BIT STATUS</h6>
    </li>
    <li data-toggle="tooltip" title="" data-placement="right">
      {item.value === "false" ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} fixedWidth className="me-1" />&nbsp;
      <span>Good</span></> : <><FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red' }} fixedWidth className="me-1" />&nbsp;
      <span>Fault Detected</span></>}
    </li>
    <li data-toggle="tooltip" title="" data-placement="right">
    <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" />&nbsp;
      <span>Last Report: {dateFromNow(item.lastReport)}</span>
    </li>
  </ul>
</Card.Body>
</Card>
))}

{states && states.map((item) => (
(item?.type === "urban64-fault2") &&
<Card key={item.id} className={`mb-0 ${(!title && !detail) ? 'border-top' : ''}`}>
<Card.Body className={detail ? 'p-0' : ''}>
  <ul className="list-unstyled col-sm-12">
    <li data-toggle="tooltip" title="" data-placement="right">
      <h6>DF BIT STATUS</h6>
    </li>
    <li data-toggle="tooltip" title="" data-placement="right">
      {item.value === "false" ? <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} fixedWidth className="me-1" />&nbsp;
      <span>Good</span></> : <><FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red' }} fixedWidth className="me-1" />&nbsp;
      <span>Fault Detected</span></>}
    </li>
    <li data-toggle="tooltip" title="" data-placement="right">
    <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" />&nbsp;
      <span>Last Report: {dateFromNow(item.lastReport)}</span>
    </li>
  </ul>
</Card.Body>
</Card>
))}

  </Fragment>
};
export default LastStates;