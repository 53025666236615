import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, Form } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../../utils/staticMethods";

const BatteryLevelPage = () => {

  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const childRef = useRef();
  const { level } = queryString.parse(location.search);
  const [curLevel, setCurrent] = useState(level)

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: `/assets/edit`,
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return <><MapPin width={14} height={14} className="me-1" />{dateFromNow(cell.value)}</>;
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
      }
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: cell => (
        <span>{cell.row.original?.lastPosition?.supply !== null ? cell.row.original.lastPosition.supply + ' Mv' : '-'}</span>
      )
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={i} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>),
      disableSortBy: true
    }
  ];

  const highThreshold = () => {
    switch (curLevel) {
      case 'High':
        return 40000;
      case 'Low':
        return 11500;
      default:
        return 12250;
    }
  }

  const lowThreshold = () => {
    switch (curLevel) {
      case 'High':
        return 12251;
      case 'Low':
        return 0;
      default:
        return 11501;
    }
  }


  return  <React.Fragment>
    <Helmet defer={false}  title={`Battery Level`} />
    <Container fluid className="p-0">
      <div className="d-flex align-items-center mb-3">
        <h1 className="h3 mb-0">Battery Level {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
        <Form.Select defaultValue={level} onChange={(e) => {
          setCurrent(e.target.value)
          setTimeout(() => {
            childRef.current && childRef.current.updateTable(1)
          }, 100)
        }} className="w-25 ms-4">
          <option value={'High'}>High</option>
          <option value={'Medium'}>Medium</option>
          <option value={'Low'}>Low</option>
        </Form.Select>
      </div>
      <Card className="bg-white p-3">
        <CommonTable ref={childRef} secFilterId={'highThreshold'} secId={highThreshold()} id={lowThreshold()} filterId={'lowThreshold'} setCountParent={setResultCount} apiName={`reports/assetsbysupply`} columns={columns}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default BatteryLevelPage