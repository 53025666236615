import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { faBatteryFull, faBatteryHalf, faBatteryQuarter, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssetBatteryLevel = ({ asset, assetId }) => {

  const [level, setLevel] = useState('')

  useEffect(() => {
    if (asset) {
      getSupplyDetails(asset?.lastPosition?.supply)
    }
  }, [assetId]);

  // <11.5, 11.5-12.25, 12.25< (Red,Amber,Green)
  const getSupplyDetails = (value) => {
    let levelVal = ''

    if (value > 12250) {
      levelVal = 'High'
    }
    else if (value > 11500 && value <= 12250) {
      levelVal = 'Medium'
    }
    else if (value >= 0 && value <= 11500) {
      levelVal = 'Low'
    }
    else {
      levelVal = 'Waiting for data'
    }

    setLevel(levelVal)
  }

  return <React.Fragment>
    <Card className="border flex-grow-1">
      <Card.Header className="pb-0">
        <Card.Title>
          Asset Battery Level
        </Card.Title>
      </Card.Header>
      {
        asset ?
          <Card.Body style={{ minHeight: '200px' }} className="d-flex flex-column align-item-center justify-content-between">
            <div className="mt-2 text-center">
              {level === "High" && <FontAwesomeIcon icon={faBatteryFull} size="6x" className="srl-green" />}
              {level === "Medium" && <FontAwesomeIcon icon={faBatteryHalf} size="6x" className="srl-canary" />}
              {level === "Low" && <FontAwesomeIcon icon={faBatteryQuarter} size="6x" className="srl-red" />}
              {level === "Waiting for data" && <FontAwesomeIcon icon={faHourglassHalf} size="6x" className="grey-500" />}
            </div>

            <h6 className="mt-2 text-center">{level}</h6>
            <div className="text-center mb-2">
              {level && asset.lastPosition && asset.lastPosition.supply !== null && <span>{asset.lastPosition.supply} Mv</span>}
            </div>
          </Card.Body>
          :
          <Card.Body style={{ minHeight: '200px' }} className="d-flex align-item-center justify-content-center">
            <span className="d-flex align-items-center">Waiting for data</span>
          </Card.Body>
      }
    </Card>
  </React.Fragment>
}

export default AssetBatteryLevel;
