export const globalConfig = {
    apiKey: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.BASE_URL_DEV : window._TRACLOGIK_CONFIG_.BASE_URL_PRO,
    googleMapKey: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.REACT_APP_GOOGLE_API_KEY_DEV : window._TRACLOGIK_CONFIG_.REACT_APP_GOOGLE_API_KEY_PRO,
    weatherApiKey: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.REACT_APP_WEATHER_API_KEY_DEV : window._TRACLOGIK_CONFIG_.REACT_APP_WEATHER_API_KEY_PRO,
    cloudmersiveApiKey: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.REACT_APP_CLOUDMERSIVE_API_KEY_DEV : window._TRACLOGIK_CONFIG_.REACT_APP_CLOUDMERSIVE_API_KEY_PRO,
    applicationTheme: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.APP_THEME_DEV : window._TRACLOGIK_CONFIG_.APP_THEME_PRO,
    productName: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.PRODUCT_NAME_DEV : window._TRACLOGIK_CONFIG_.PRODUCT_NAME_PRO,
    supportEmail: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.SUPPORT_EMAIL_DEV : window._TRACLOGIK_CONFIG_.SUPPORT_EMAIL_PRO,
    supportPhone: process.env.NODE_ENV === "development" ? window._TRACLOGIK_CONFIG_.SUPPORT_PHONE_DEV : window._TRACLOGIK_CONFIG_.SUPPORT_PHONE_PRO,
    themeList: {
        srl: {
            logo: "./../../assets/img/traclogik-logo.png"
        }, default: {
            logo: "./../../assets/img/traclogik-logo.png"
        }
    },
    // widgets list
    widgetsConfig: [{
        name: 'AverageBatteryLifespan',
        label: 'Average Battery Lifecycle',
        path: './components/widgets/AverageBatteryLifespan',
        src: 'lifecycle.png',
    }, {
        name: 'AssetSummary',
        label: 'Asset Summary',
        path: './components/widgets/Summary',
        src: 'assetSummary.png',
    }, {
        name: 'BatteryLevel',
        label: 'Asset Battery Level',
        path: './components/widgets/BatteryLevel',
        src: 'batteryLevel.png',
    }, {
        name: 'BatteryExchanges',
        label: 'Asset Battery Exchanges',
        path: './components/widgets/BatteryExchanges',
        src: 'batteryExchange.png',
    }, {
        name: 'CagesByDepot',
        label: 'Cages By Depot',
        path: './components/widgets/CagesByDepot',
        src: 'cages.png',
    }, {
        name: 'CagesByCustomer',
        label: 'Cages By Customer',
        path: './components/widgets/CagesByCustomer',
        src: 'cages.png',
    }, {
        name: 'CagesByCustomerWithAllocation',
        label: 'Cages By Customer (with Allocation)',
        path: './components/widgets/CagesByCustomerWithAllocation',
        src: 'cages.png',
    }, {
        name: 'CagesDistribution',
        label: 'Cages Distribution',
        path: './components/widgets/CageDistribution',
        src: 'distribution.png',
    }, {
        name: 'CagesInTransit',
        label: 'Cages In Transit',
        path: './components/widgets/CagesInTransit',
        src: 'cages.png',
    }, {
        name: 'CratesByDepot',
        label: 'Crates By Depot',
        path: './components/widgets/CratesByDepot',
        src: 'cages.png',
    }, {
        name: 'CratesByCustomer',
        label: 'Crates By Customer',
        path: './components/widgets/CratesByCustomer',
        src: 'cages.png',
    }, {
        name: 'CratesByCustomerWithAllocation',
        label: 'Crates By Customer (with Allocation)',
        path: './components/widgets/CratesByCustomerWithAllocation',
        src: 'cages.png',
    }, {
        name: 'CratesDistribution',
        label: 'Crates Distribution',
        path: './components/widgets/CrateDistribution',
        src: 'distribution.png',
    }, {
        name: 'CratesInTransit',
        label: 'Crates In Transit',
        path: './components/widgets/CratesInTransit',
        src: 'cages.png',
    }, {
        name: 'Deployment',
        label: 'Deployment',
        path: './components/widgets/Deployment',
        src: 'deployment.png',
    }, {
        label: 'Favourite Assets',
        name: 'FavouriteAssets',
        path: './components/widgets/Favourites',
        src: 'favouriteAssets.png',
    }, {
        label: 'Favourite People',
        name: 'FavouritePeople',
        path: './components/widgets/Favourites',
        src: 'favouritePeople.png',
    }, {
        name: 'FavouriteZones',
        label: 'Favourite Zones',
        path: './components/widgets/Favourites',
        src: 'favouriteZone.png',
    }, {
        label: 'Mini Map',
        name: 'MiniMap',
        path: './components/widgets/MiniMap',
        src: 'map.png',
    }, {
        label: 'Mini Floorplan',
        name: 'MiniFloorplan',
        path: './components/widgets/MiniFloorplan',
        src: 'floorplan.png',
    }, {
        name: 'PeopleSummary',
        label: 'People Summary',
        path: './components/widgets/Summary',
        src: 'peopleSummary.png',
    }, {
        label: 'Recent Items',
        name: 'RecentItems',
        path: './components/widgets/RecentItems',
        src: 'recentItem.png',
    }, {
        name: 'ZoneSummary',
        label: 'Zones Summary',
        path: './components/widgets/Summary',
        src: 'zoneSummary.png',
    }, {
        name: 'TagAssignment',
        label: 'Tag Assignment',
        path: './components/widgets/TagAssignment',
        src: 'AssignTagTruck.png',
    }, {
        name: 'MultiAssetSearch',
        label: 'Multi Asset Search',
        path: './components/widgets/MultiAssetSearch',
        src: 'AssignTagTruck.png',
    }, {
        name: 'ZoneContentsCount',
        label: 'Zone Contents Count',
        path: './components/widgets/ContentsCountWidget',
        src: 'assetSummary.png',
    }, {
        name: 'TagAssignmentCount',
        label: 'Tag Assignment Count',
        path: './components/widgets/TagAssignmentCountWidget',
        src: 'assetSummary.png',
    }, {
        name: 'ZoneMapWidget',
        label: 'Zone Map',
        path: './components/widgets/ZoneMapWidget',
        src: 'zonemap.png',
    }, {
        name: 'ZoneMapImageWidget',
        label: 'Zone Map Image',
        path: './components/widgets/ZoneMapImageWidget',
        src: 'ZoneMapImageIcon.png',
    }, {
        name: 'AssetStateCountWidget',
        label: 'Asset State Count',
        path: './components/widgets/AssetStateCountWidget',
        src: 'AssetStateCountWidget.png',
    }
    ],
    // assets widgets list
    assetWidgets: [
        {
            label: 'Asset Battery Lifecycle',
            name: 'AssetAverageBatteryLifespan',
            path: './components/statistics/AssetAverageBatteryLifespan',
            src: 'battery.png',
        }, {
            name: 'AssetBatteryExchanges',
            label: 'Asset Battery Exchanges',
            path: './components/statistics/AssetBatteryExchanges',
            src: 'barChart.png',
        }, {
            name: 'AssetUtilisation',
            label: 'Asset Utilisation Hours',
            path: './components/statistics/AssetUtilisation',
            src: 'hours.png',
        }, {
            name: 'AssetBatteryVoltage',
            label: 'Asset Battery Voltage',
            path: './components/statistics/AssetBatteryVoltage',
            src: 'lineChart.png',
        }, {
            name: 'AssetBatteryLevel',
            label: 'Asset Battery Level',
            path: 'components/statistics/AssetBatteryLevel',
            src: 'guage.png',
        }
    ],
    reportWidgets: [
        {
            name: 'AttendanceHistWidget',
            label: 'All attendances history report',
            path: './components/widgets/AttendanceHistWidget',
            src: 'zoneSummary.png',
        }, {
            name: 'AttendanceLiveWidget',
            label: 'All live attendances report',
            path: './components/widgets/AttendanceLiveWidget',
            src: 'zoneSummary.png',
        }
    ]
};
