import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import NewObject from "../../components/detail/NewObject";
import CommonTable from "../../components/tables/CommonTable";
import { MapPin, Moon, Package } from "react-feather";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import { dateFromNow } from "../../utils/staticMethods";

function ElementStateUrban64WithoutFault2Page() {

    const [resultCount, setResultCount] = useState(0);
    const [showModal, setShow] = useState(false);
    const curTime = useRef(moment())
    const { user } = useAuth();

    const columns = [
        {
            Header: "",
            accessor: "icon",
            type: "icon",
            disableSortBy: true
        },
        {
            Header: "Name (Ref)",
            accessor: "compoundName",
            type: "link",
            link: "/assets/edit",
            stateId: "id"
        },
        {
            Header: "Last Seen",
            accessor: "lastSeen",
            Cell: (cell) => {
                if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
                    return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>;
                }
                return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
            }
        },
        {
            Header: "Customer",
            accessor: "customerName",
            show: user?.role === "Root",
            disableSortBy: true,
            Cell: cell => (<NavLink
                to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
        },
        {
            Header: "Hashtags",
            accessor: "hashTags",
            disableSortBy: true,
            Cell: cell => (<div>
                {cell.value && cell.value.map((tag, i) => <NavLink key={tag} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span key={i} className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
            </div>)
        }
    ];

    return (
        <React.Fragment>
            <Helmet defer={false} title="Assets" />
            <Container fluid className="p-0">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                        <h1 className="h3 mb-0"><Package className="me-2" /> Assets without Detector Fault {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
                    </div>
                </div>
                <Card className="bg-white p-3">
                    <CommonTable setCountParent={setResultCount} apiName="assets" columns={columns} showExport={true} filterId={"stateType"} id={"urban64-fault2"} secFilterId={"stateValue"} secId={"false"} />
                </Card>
            </Container>
            <NewObject showModal={showModal} closeModal={() => setShow(false)} apiName="assets" />
        </React.Fragment>
    );
}
export default ElementStateUrban64WithoutFault2Page;
