import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, role }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  // check the user role, make sure that different user types can only see their own menu
  if (user?.role!=='Root' && isInitialized && isAuthenticated && role && !role.includes(user?.role)) {
    return <Navigate to="/" />;
  }

  if (user?.role ==='Root' && isInitialized && isAuthenticated && role && !role.includes(user?.role)) {
    return <Navigate to="/customers" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
