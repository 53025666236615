import api from "../../api";
import React, { useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { ListGroup, Spinner } from "react-bootstrap";
import { Disc, Package, Users } from "react-feather";

const nameArr = ["Assets", "People", "Zones"];

// A selector component for selecting assets/people/zones

const GroupSelector = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showing, setShowing] = useState(false);
  const [current, setCurrent] = useState(null);
  const isUmount = useRef(false);

  const onSearch = (search) => {
    if (search && search.length > 0) {
      const apiNameArr = ["assets", "people", "zones"];
      const apiArray = [];
      apiNameArr.forEach(item => {
        apiArray.push(api.get(`${item}?page=1&limit=10`, {
          params: {
            filter: search
          },}));
      });
      setLoading(true);
      Promise.all(apiArray).then(res => {
        if(isUmount.current) return
        let arr = [];
        res.forEach((item, index) => {
          let obj = {};
          let eArr = [];
          item.data.forEach(item1 => {
            let subObj = item1;
            subObj.value = item1.id;
            subObj.label = item1.compoundName;
            subObj.type = apiNameArr[index];
            eArr.push(subObj);
          });
          if (eArr.length > 0) {
            obj.label = nameArr[index];
            obj.options = eArr;
            arr.push(obj);
          }
        });
        setData(arr);
        setLoading(false);
        setShowing(true);
      }).catch(() => setLoading(false));
    }
  };

  useEffect(()=> {
    document.onclick = () => {
      setShowing(false);
      props.cancelOption && props.cancelOption()
    }
    return () => {
      isUmount.current = true
    }
  }, [])

  const getIcon = (type) => {
    if(type=== 'assets') {
      return <Package size={14} />
    } else if (type === 'people') {
      return <Users size={14}/>
    } else {
      return <Disc size={14} />
    }
  }


  return <div className="z-100 w-100 position-relative">
    <DebounceInput
      className="hashtag-input text-gray w-100"
      style={{border: '1px solid #7f838e', padding: '4px 8px', fontSize: '12px', height: '32px', margin: '10px 10px 0'}}
      minLength={0}
      debounceTimeout={500}
      value={searchString}
      placeholder="Search Assets, People, Zones"
      onClick={e=> e.stopPropagation()}
      onFocus={(e) => {setShowing(true)}}
      onChange={event => {onSearch(event.target.value)}}
    />

    {loading && <div className="w-100 position-relative bg-light z-100 d-flex justify-content-center p-2" style={{marginLeft: '10px'}}><Spinner animation="border" /> </div>}
    <div style={{maxHeight: '500px', marginLeft: '10px'}} className="overflow-auto w-100 z-100 position-relative">
      {showing && !loading && data.map((item1, i) => <div className="bg-light" key={i}>
        <div className="d-flex align-items-center ms-2">{item1.label}</div>
        <ListGroup>
          {item1.options && item1.options.length > 0 && item1.options.map((item, index) => <ListGroup.Item
            onClick={(event) => {
              event.stopPropagation();
              setSearchString(item.compoundName);
              props.onSelect && props.onSelect(item);
              setShowing(false);
              setCurrent(item);
            }} key={index} className={`cursor-pointer list-group-hover p-2 ${current && item.id === current.id ? 'bg-primary' : ''}`}>
            <div>
              {getIcon(item.type)}
              <span className="ms-1">{item.compoundName}{item.reference ? ` (${item.reference})` : ''}</span>
            </div>
            {item.hashTags && Array.isArray(item.hashTags) && item.hashTags.map(item => <span key={item} className='badge bg-primary me-1 my-1'>{item}</span>)}
          </ListGroup.Item>)}
        </ListGroup>
      </div>)}
    </div>
  </div>;
};

export default GroupSelector;