import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import moment from "moment-timezone";
import useAuth from "../../hooks/useAuth";
import UserLogin from "../../components/navbar/UserLogin";
import CommonTable from "../../components/tables/CommonTable";

let data = {};
let source1 = null;

const AdminDetail = (props) => {

  const notify = useContext(NotyfContext);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [curPermission, setCurPermission] = useState(null);
  const { user } = useAuth();
  let sendType = null;
  const formRef = useRef();

  const columns = [
    {
      Header: "Profile Name",
      accessor: "permissionName",
      type: "link",
      link: "/accessprofiles/edit",
      stateId: "id"
    }, {
      Header: "Users",
      accessor: "userCount",
      disableSortBy: true
    }, {
      Header: "Assets",
      accessor: "assetCount",
      disableSortBy: true,
      Cell: cell => (
        <span>
        {cell.row.original.allAssets ? "All" : cell.value}
      </span>
      )
    }, {
      Header: "People",
      accessor: "personCount",
      disableSortBy: true,
      Cell: cell => (
        <span>
        {cell.row.original.allPeople ? "All" : cell.value}
      </span>
      )
    }, {
      Header: "Zones",
      accessor: "zoneCount",
      disableSortBy: true,
      Cell: cell => (
        <span>
        {cell.row.original.allZones ? "All" : cell.value}
      </span>
      )
    }, {
      Header: "Action",
      accessor: "id",
      disableSortBy: true,
      Cell: cell => (
        curPermission && curPermission.id === cell.value ? null : <Button onClick={async () => {
          await linkPermission(cell.value);
          setCurPermission(cell.row.original);
          setShow(false);
        }} variant={"primary"}>Choose</Button>
      )
    }
  ];

  useEffect(() => {
    if (props.data.permissionId) {
      getDetail();
    }
  }, [props.data.permissionId]);

  useEffect(() => {
    return () => {
      if (source1) {
        source1.cancel("request canceled");
      }
    };
  }, []);

  const linkPermission = async (id) => {
    try {
      await api.post(`accessprofiles/${id}/users/link/${props.data.id}`)
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    } catch (e) {
      console.log(e)
    } finally {
      setShow(false)
      setShowDelete(false)
    }
  }

  const unLinkPermission = async () => {
    try {
      await api.post(`accessprofiles/${curPermission.id}/users/unlink/${props.data.id}`)
      setCurPermission(null)
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    } catch (e) {
      console.log(e)
    } finally {
      setShow(false)
      setShowDelete(false)
    }
  }

  const getDetail = () => {
    api.get(`accessprofiles/${props.data.permissionId}`).then(res => {
      setCurPermission(res.data);
    });
  };

  const saveAndSend = () => {
    api.put(`accounts/user`, data)
      .then(res => {
        props.setPerson(res.data);
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
      });
  };
  const justSave = () => {
    api.put(`accounts/user`, data).then(res => {
      props.setPerson(res.data);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    });
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <h4>
            <FontAwesomeIcon icon={faCog}></FontAwesomeIcon> System Access
          </h4>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              email: props.data.email || "",
              create: props.data.create || false,
              edit: props.data.edit || false,
              delete: props.data.delete || false,
              permissionId: props.data.permissionId || "",
              submit: false
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email("Invalid email")
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              data = JSON.parse(JSON.stringify(props.data));
              data.id = props.data.id;
              data.email = values.email;
              data.create = values.create;
              data.edit = values.edit;
              data.delete = values.delete;
              data.permissionId = curPermission ? curPermission.id : null;
              if (sendType === 1) {
                data.sendemail = true;
                saveAndSend();
                return;
              } else {
                try {
                  data.sendemail = false;
                  justSave();
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values
              }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>
                    <span className="me-1">Access Profile</span>
                  </Form.Label>
                  <div>
                    {curPermission && <NavLink to={{
                      pathname: curPermission ? `/accessprofiles/edit` : "/accessprofiles",
                      search: curPermission ? `?id=${curPermission.id}` : ""
                    }}>
                      {curPermission?.permissionName}</NavLink>}
                    {!curPermission && <span>No Access</span>}
                  </div>
                  <div className="mt-2">
                    <Button onClick={() => setShow(true)}>Choose Access</Button>
                    {curPermission && <Button onClick={() => setShowDelete(true)} className="ms-2" variant={"danger"}>Remove
                      Access</Button>}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="email"
                    value={values.email}
                    isInvalid={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {user?.globalCrud && <>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="create"
                      label="Create"
                      checked={values.create}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="edit"
                      label="Edit"
                      checked={values.edit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="delete"
                      label="Delete"
                      checked={values.delete}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>}
                <div className="mt-4 font-weight-bold text-dark">
                  Registration Status: {props.data.registered ? "Registered" : "Not Registered"}
                </div>
                <div className="mt-2">
                  Last
                  Login: {props.data.lastLogin && props.data.lastLogin.indexOf("0001-01-01") > -1 ? "Never" : moment(props.data.lastLogin).format("DD/MM/YY HH:mm")}
                </div>
                <div className="mt-3">
                  {!props.data.registered && <Button className="me-2" disabled={isSubmitting} type="submit"
                                                     onClick={() => sendType = 1}>{"Save and send registration email"}</Button>}
                  <Button disabled={isSubmitting} type="submit" onClick={() => sendType = 2}
                          variant="success">Save</Button>
                  <UserLogin isUser id={props.data.id} homePage />
                </div>
                <Modal size={"xl"} show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Choose Access</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CommonTable apiName="accessprofiles" columns={columns} />
                  </Modal.Body>
                </Modal>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
        <Modal.Body>Are you sure that you wish to remove this person's access to the system?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            unLinkPermission()
          }} variant={"danger"}>Remove</Button>
          <Button onClick={() => setShowDelete(false)} variant={"secondary"}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AdminDetail;