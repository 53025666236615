import React from "react";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import { NavLink } from "react-router-dom";

const thingsArr = { asset: "assets", person: "people", zone: "zones", assets: 'assets', people: 'people', zones: 'zones' };

// show assets/people when clicking marker cluster
const MarkerModal = ({tableData, setTableData}) => {
  const tableColumns = [{
    Header: "",
    accessor: "icon",
    type: "icon",
    width: 40,
    headerStyle: {
      width: '40px'
    },
    disableSortBy: true
  },{
    Header: "Name",
    accessor: "compoundName",
    Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
      pathname: `/${thingsArr[cell.row.original.type]}/edit`,
      search: `?id=${cell.row.original.id}`
    }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
  }, {
    Header: "Voltage",
    accessor: "supply",
    Cell: cell => <div>{cell.value && cell.value > 0 ? `${cell.value} Mv`: ''}</div>
  }];

  return <Modal show={tableData} onHide={() => setTableData(null)}>
    <Modal.Header closeButton>
      <Modal.Title>Total Count: {tableData && tableData.length}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CommonTable data={tableData} columns={tableColumns} />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setTableData(null)} variant={"secondary"}>Close</Button>
    </Modal.Footer>
  </Modal>
}
export default MarkerModal