import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import usePalette from "../../hooks/usePalette";
import { useNavigate } from "react-router-dom";

const AssetStateCountWidget = ({ name, width }) => {
    // const [error, setError] = useState(undefined);
    const [fault1true, setFault1true] = useState(0);
    const [fault1false, setFault1false] = useState(0);
    const [fault2true, setFault2true] = useState(0);
    const [fault2false, setFault2false] = useState(0);
    const loadPromise = useRef(null);
    const controller = new AbortController();
    const palette = usePalette();
    const navigate = useNavigate();

    const showAssets = (stateType, stateValue) => {
        navigate(`/state/assets/${stateType}/${stateValue}`);
    };

    const loadData = () => {
        if (loadPromise.current === null) {
            // setError(undefined);
            loadPromise.current = api.get('reports/statecounts?elementtype=asset', { signal: controller.signal })
                .then((response) => {
                    if (response.status === 200) {
                        setFault1true(response.data.find((v) => { return v.type === "urban64-fault1" && v.value === "true" })?.count ?? 0);
                        setFault1false(response.data.find((v) => { return v.type === "urban64-fault1" && v.value === "false" })?.count ?? 0);
                        setFault2true(response.data.find((v) => { return v.type === "urban64-fault2" && v.value === "true" })?.count ?? 0);
                        setFault2false(response.data.find((v) => { return v.type === "urban64-fault2" && v.value === "false" })?.count ?? 0);
                    }
                    else {
                        // setError(response.message);
                    }
                })
                .catch((reason) => {
                    // setError(reason.message ?? reason ?? "error");
                })
                .finally(() => {
                    loadPromise.current = null;
                });
        }
    };

    useEffect(() => {
        loadData();

        let intervalId = setInterval(() => { loadData(); }, 5000);

        return (() => {
            controller.abort();

            if (intervalId) {
                clearInterval(intervalId);
            }
        });
    }, []);

    return (
        <>
            <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
                <Row>
                    <Col lg={6}>
                        <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                            <Card.Title className="p-3">Controller Faults</Card.Title>
                            <Card.Text className="p-3" as="div">
                                <div className="mt-auto justify-content-between p-2">
                                    <div className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-danger`} onClick={() => { showAssets('urban64-fault1', 'true'); }} style={{ cursor: "pointer" }}>
                                        <div className='px-1'><FontAwesomeIcon icon={faExclamationCircle} style={{ color: palette.danger }} fixedWidth className="me-1" /><span className='px-2'>Fault</span></div>
                                        <div className={`w-25 bg-danger text-bg-danger  p-1`}><span className='float-end'><b>{fault1true}</b></span></div>
                                    </div>
                                    <div className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-success`} onClick={() => { showAssets('urban64-fault1', 'false'); }} style={{ cursor: "pointer" }}>
                                        <div className='px-1'><FontAwesomeIcon icon={faCheckCircle} style={{ color: palette.success }} fixedWidth className="me-1" /><span className='px-2'>Good</span></div>
                                        <div className={`w-25 bg-success text-bg-success  p-1`}><span className='float-end'><b>{fault1false}</b></span></div>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                            <Card.Title className="p-3">Detector Faults</Card.Title>
                            <Card.Text className="p-3" as="div">
                                <div className="mt-auto justify-content-between p-2">
                                    <div className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-danger`} onClick={() => { showAssets('urban64-fault2', 'true'); }} style={{ cursor: "pointer" }}>
                                        <div className='px-1'><FontAwesomeIcon icon={faExclamationCircle} style={{ color: palette.danger }} fixedWidth className="me-1" /><span className='px-2'>Fault</span></div>
                                        <div className={`w-25 bg-danger text-bg-danger  p-1`}><span className='float-end'><b>{fault2true}</b></span></div>
                                    </div>
                                    <div className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-success`} onClick={() => { showAssets('urban64-fault2', 'false'); }} style={{ cursor: "pointer" }}>
                                        <div className='px-1'><FontAwesomeIcon icon={faCheckCircle} style={{ color: palette.success }} fixedWidth className="me-1" /><span className='px-2'>Good</span></div>
                                        <div className={`w-25 bg-success text-bg-success  p-1`}><span className='float-end'><b>{fault2false}</b></span></div>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card>
                    </Col>
                </Row >
            </Col >
        </>
    );
};

export default AssetStateCountWidget;
