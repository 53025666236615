import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faCheckSquare,
  faClock,
  faExchangeAlt,
  faGlobe,
  faHourglassHalf,
  faPlug,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";
import { dateFromNow } from "../utils/staticMethods";

const LastLocation = ({ profile, detail, markerLabel, isPosition, apiName }) => {

  const item = profile?.lastPosition;

  const getBatteryIcon = (battery) => {
    if (battery >= 10 && battery < 25) {
      return faBatteryQuarter;
    } else if (battery < 10) {
      return faBatteryEmpty;
    } else if (battery >= 25 && battery <= 50) {
      return faBatteryHalf;
    } else if (battery > 50 && battery <= 75) {
      return faBatteryThreeQuarters;
    } else {
      return faBatteryFull;
    }
  };

  const getSupply = (supply) => {
    if (supply !== null) if (supply === 0) {
      return (
        <li>
          <FontAwesomeIcon icon={faPlug} fixedWidth className="fa-fw me-1" />
          &nbsp;
          <span>Disconnected</span>
        </li>);
    } else if (supply === 1) {
      return (
        <li>
          <FontAwesomeIcon icon={faPlug} fixedWidth className="fa-fw me-1" />
          &nbsp;
          <span>Connected</span>
        </li>
      );
    } else {
      return (
        <li>
          <FontAwesomeIcon icon={faPlug} style={{ color: 'green' }} fixedWidth className="fa-fw me-1" />
          {supply}
          <span>&nbsp;Mv</span>
        </li>
      );
    }
  };

  let speed = item && (item.speed !== null || item.battery !== null) ?
    <li data-toggle="tooltip" title="" data-placement="right">
      {item.speed !== null && <><FontAwesomeIcon icon={faTachometerAlt} fixedWidth
        className="me-1" /> {`${Math.round((item.speed * 0.6214))} mph`}</>}
      {item.battery !== null && <>
        {item.speed !== null && <span className="m-2">|</span>}
        <FontAwesomeIcon icon={getBatteryIcon(item.battery)} fixedWidth className="me-1" /> {`${item.battery} %`}
      </>}
    </li> : null

  return (
    <Card className="mb-0">
      <Card.Body className={detail ? 'p-0' : ''}>
        <ul className={`list-unstyled ${markerLabel && 'mb-2'}`}>
          {!markerLabel && <li data-toggle="tooltip" title="" data-placement="right">
            <h6>LAST LOCATION</h6>
          </li>}
          {!isPosition && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" /> Reported: {dateFromNow(item?.dateTime)}
          </li>}
          {!isPosition && !markerLabel && item?.lastFix.indexOf('0001-01-01') < 0 && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" /> Fix: {item?.type === 'manual' ? ' manual' : dateFromNow(item?.lastFix)}
          </li>}
          {!isPosition && markerLabel && item?.lastFix.indexOf('0001-01-01') < 0 && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" /> Fix: {(item?.positionType === 'manual' || item?.type === 'manual') ? ' manual' : dateFromNow(item?.lastFix)}
          </li>}
          {speed}
          {getSupply(item?.supply)}
          {profile?.lastCommand && !markerLabel && <li>
            <FontAwesomeIcon icon={faExchangeAlt} fixedWidth className="me-1" /> {profile?.lastCommand?.commandName} &nbsp;
            {profile?.lastCommand?.status === "Pending" && <FontAwesomeIcon icon={faHourglassHalf} className="me-1" />}
            {profile?.lastCommand?.status === "Confirmed" && <FontAwesomeIcon icon={faCheckSquare} className="me-1" />}
          </li>}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default LastLocation;
