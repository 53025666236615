import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";

import AssetDetails from "../../components/AssetDetails";
import AssetHasTag from "../../components/AssetHasTag";
import Device from "./Device";

import Notes from "../../components/detail/Notes";
import Geofence from "./Geofence";
import NotyfContext from "../../contexts/NotyfContext";
import ZoneOverviewTab from "./ZoneOverViewTab";
import * as queryString from "query-string";
import FavouriteModal from "../../components/favourite/FavouriteModal";
import Diagnostics from "../../components/detail/Diagnostics";
import useAuth from "../../hooks/useAuth";
import UploadImgModal from "../../components/UploadImgModal";
import TasksTable from "../task/TasksTable";
import QRCodeComponent from "../../components/detail/QRCodeComponent";
import FloorPlanEdit from "../floorplans/FloorPlanEdit";
import { blobToFile, compressPic, dataURLtoBlob, filterStorage } from "../../utils/staticMethods";
import RootTopBar from "../../components/navbar/RootTopBar";
import axios from "axios";
import WeatherBar from "../../components/WeatherBar";

let interval;
let source1 = null;
const ZonesEdit = () => {
  const location = useLocation()
  const { id, direct } = queryString.parse(location.search);
  const [zone, setZone] = useState({});
  const [readers, setReaders] = useState(null);
  const notify = useContext(NotyfContext);
  const [tab, setTab] = useState('overview');
  const [picture, setPicture] = useState(null);
  const [showUpload, setShowUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [hasFloorPlans, setHasFloorPlan] = useState(false);
  const geoRef = useRef();
  const floorplanRef = useRef();
  const { user } = useAuth();

  useEffect(() => {
    getZone();
    getPci();
    getFloorPlan();
    if (direct) {
      setTab(direct);
    }
    interval && clearInterval(interval)
    interval = setInterval(() => {
      getZone();
    }, 5000);
  }, [id]);

  useEffect(() => {
    return () => {
      clearInterval(interval)
      if (source1) {
        source1.cancel('request canceled');
      }
    }
  }, [])

  const getZone = () => {
    if (source1) {
      source1.cancel('request canceled');
    }
    source1 = axios.CancelToken.source();
    api.get(`zones/` + id, {
      cancelToken: source1.token
    })
      .then(res => {
        setZone(res.data);
      })
  };

  useEffect(() => {
    if (zone?.id) {
      let obj = {
        link: location?.pathname,
        search: location?.search,
        name: `${zone.compoundName} ${zone.reference ? `(${zone.reference})` : ""}`,
        icon: zone.icon,
        id: zone.id
      }
      filterStorage(obj)
    }
  }, [zone])

  const getFloorPlan = () => {
    api.get(`floorplans?parentId=${id}`).then(res => {
      onComplete(res.data.length)
    })
  }

  const getPci = () => {
    api.get(`files/zones/${id}/profilepic`, {
      responseType: "arraybuffer"
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null))
  }

  const onDelete = () => {
    api.delete(`files/zones/${id}/profilepic`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setPicture(null);
      setShowUpload(false);
    });
  }

  const onUpload = () => {
    if (!uploadFile || uploadFile.length === 0) return;
    let reader = new FileReader();
    reader.onload = function (evt) {
      let replaceSrc = evt.target.result;
      compressPic(replaceSrc, base => {
        let blob = dataURLtoBlob(base)
        let miniFile = blobToFile(blob, 'new' + uploadFile[0].name, uploadFile[0].type)
        let data = new FormData();
        data.append("uploadedFile", miniFile);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        api.post(`files/zones/${id}/profilepic`, data, config).then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setShowUpload(false);
          getPci();
        }).catch(err => {
          notify.open({
            type: "error",
            message: err
          });
        });
      });
    };
    reader.readAsDataURL(uploadFile[0]);
  };

  const onUpdate = (obj) => {
    api.put(`zones`, obj)
      .then(res => {
        setZone(res.data);
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
      })
  }

  const handleUpdateData = () => {
    getZone();
  }

  const onGetList = (list) => {
    setReaders(list)
  }

  const updateRole = (e) => {
    let obj = JSON.parse(JSON.stringify(zone));
    obj.role = e.target?.checked ? 'StickyZone' : null
    api.put(`zones`, obj)
      .then(res => {
        setZone(res.data);
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
      })
  }

  const onTabChange = (e) => {
    setTab(e);
  }

  const onComplete = (len) => {
    len > 0 ? setHasFloorPlan(true) : setHasFloorPlan(false)
  }

  return (
    <React.Fragment>
      <Helmet defer={false} title={zone.compoundName || 'Zone'} />
      <RootTopBar data={zone} />
      <Container fluid className="p-0">
        {zone.compoundName && <h1 className="h3 mb-3">{`${zone.compoundName} ${zone.reference ? `(${zone.reference})` : ''}`} <FavouriteModal id={zone.id} type="zones" /></h1>}
        <Row>
          <Col md="4" xl="3">
            {picture && <div className="p-3 bg-white border-bottom">
              <img alt={'profile'} className="w-100 cursor-pointer" src={picture} onClick={() => {
                if (user?.editZones && user?.role !== 'Root') {
                  setShowUpload(true)
                }
              }} />
            </div>}
            {zone.latitude && zone.longitude ? (<WeatherBar data={zone} />) : null}
            {zone.id && <AssetHasTag apiName="zones" handleUpdateData={handleUpdateData} id={id} hashTags={zone.hashTags} />}
            {!picture && user?.editAssets && user?.role !== 'Root' && <div className="border-top p-3 bg-white">
              <span className="text-primary cursor-pointer" onClick={() => {
                if (user?.editZones && user?.role !== 'Root') {
                  setShowUpload(true)
                }
              }}>Add Profile Picture</span>
            </div>}
          </Col>
          <Col md="8" xl="9" className="mt-3 mt-sm-0">
            <div className="tab">
              <Tab.Container id="left-tabs-example" activeKey={tab} onSelect={(e) => onTabChange(e)}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                  {user?.role === 'Root' && <Nav.Item>
                    <Nav.Link eventKey="floorplan">Floorplans</Nav.Link>
                  </Nav.Item>}
                  {(((user?.role === 'Admin' || user?.role === 'User') && !hasFloorPlans) || user?.role === 'Root') && <Nav.Item>
                    <Nav.Link eventKey="geofence">Geofence</Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link eventKey="devices">Devices</Nav.Link>
                  </Nav.Item>
                  {user?.showTasks && <Nav.Item>
                    <Nav.Link eventKey="tasks">Tasks</Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    <Nav.Link eventKey="diagnostics">Diagnostics</Nav.Link>
                  </Nav.Item>
                  {user?.viewNotes && user?.role !== 'Root' && <Nav.Item>
                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                  </Nav.Item>}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <ZoneOverviewTab lastPosition={zone.lastPosition} id={id} compoundName={zone.compoundName} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="details">
                    {zone.icon ? (<AssetDetails apiName="zones" type="zones" asset={zone} setAsset={setZone} handleUpdateData={handleUpdateData} />) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="floorplan">
                    {zone.id && <FloorPlanEdit ref={floorplanRef} geoDeleted={() => {
                      if (geoRef.current) {
                        geoRef.current.deletedMap()
                      }
                    }} onComplete={onComplete} tab={tab} zone={zone} onUpdate={onUpdate} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="geofence">
                    <Geofence ref={geoRef} tab={tab} geoDeleted={() => {
                      if (floorplanRef.current) {
                        floorplanRef.current.deletedMap()
                      }
                    }} zone={zone} onUpdate={onUpdate} />
                    {user?.editZones && <Form.Check
                      className="mt-2"
                      type="checkbox"
                      label="Sticky Zone"
                      checked={zone?.role === 'StickyZone'}
                      onChange={(e) => updateRole(e)}
                    />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="devices">
                    <Device type="zones" onGetList={onGetList} id={id} data={zone} />
                    <QRCodeComponent value={id} type={'Zone'} icon={zone.icon} name={zone.compoundName} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="tasks">
                    {zone.id && <TasksTable type={'zones'} id={id} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diagnostics">
                    <Diagnostics readers={readers} root />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notes">
                    <Notes id={id} type="zones" />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <UploadImgModal setUploadFile={(e) => setUploadFile(e)} showUpload={showUpload} onDelete={onDelete}
        onHide={() => setShowUpload(false)} picture={picture} onUpload={onUpload} />
    </React.Fragment>
  );
};

export default ZonesEdit;
