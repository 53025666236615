import React, { useEffect, useRef, useState } from "react";
import "izitoast-react/dist/iziToast.css";
import { Button, Form, InputGroup, Nav, Navbar } from "react-bootstrap";
import audioSound from "./../../assets/alert.mp3";

import { Bell, Search } from "react-feather";
import { immediateToast } from "izitoast-react";
import useSidebar from "../../hooks/useSidebar";

import NavbarUser from "./NavbarUser";
import { useLocation, useNavigate } from "react-router-dom";
import * as queryString from "query-string";
import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import api from "../../api";
import moment from "moment-timezone";
import useAuth from "../../hooks/useAuth";
import ScanCamera from "./ScanCamera";
import { getIcon } from "../../utils/staticMethods";

let timer = null;
const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const [searchText, setText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [showAdditional, setShowAdditional] = useState(false);
  const [showHasAdmin, setShowHasAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { searchString } = queryString.parse(location.search);
  const [notifications, setNotifyCations] = useState([]);
  const [lastCount, setCount] = useState(0);
  const lasCountRef = useRef(lastCount);
  const notification = useRef(notifications);
  const { user } = useAuth();
  const audioRef = useRef();
  const onSearch = () => {
    navigate(`/globalsearch?searchString=${encodeURIComponent(searchText)}`);
  };
  const getRecentAlertsPromise = useRef(null);

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (searchString) {
      setText(searchString);
    } else {
      setText('')
    }
  }, [searchString]);


  useEffect(() => {

    function rightCartData() {
      const item = localStorage.getItem("accessTokenAdmin");
      const item1 = localStorage.getItem("accessTokenUser");
      if (item) {
        setShowAdditional(true);
      } else {
        setShowAdditional(false);
      }
      if (item1) {
        setShowHasAdmin(true);
      } else {
        setShowHasAdmin(false);
      }
    }


    window.addEventListener("storage", rightCartData);
    rightCartData();
    return () => {
      clearInterval(timer);
      window.removeEventListener("storage", rightCartData);
    };
  }, []);

  useEffect(() => {
    if (timer) {
      clearInterval(timer);
      timer = null
    }
    if (!user) return
    if (user.role === 'Admin') {
      timer = setInterval(() => {
        getRecentAlerts();
      }, 5000);
    }
  }, [user])

  const getRecentAlerts = () => {
    if (getRecentAlertsPromise.current === null) {
      getRecentAlertsPromise.current = api.get(`alerts?page=1&limit=5&recent=true`)
        .then(res => {
          const totalHeader = Number(res.headers["x-total-count"]);
          setCount(totalHeader);
          lasCountRef.current = totalHeader;
          setNotifyCations(res.data);
        })
        .finally(() => {
          getRecentAlertsPromise.current = null;
        });
    }
  };

  useEffect(() => {
    if (notifications.length > 0) {
      notification.current = notifications;
      if (firstLoad) {
        localStorage.setItem("latestAlert", notification.current[0].id);
        setFirstLoad(false);
        return
      }
      let curId = localStorage.getItem("latestAlert");
      if (curId !== notification.current[0].id) {
        localStorage.setItem("latestAlert", notification.current[0].id);
        if (user?.playSounds) {
          audioRef.current.play();
        }
        if (user?.screenAlerts) {
          showMessage(notifications[0]);
        }
      }
    }
  }, [notifications]);

  const showMessage = (item) => {
    immediateToast("info", {
      class: "bg-primary text-dark",
      message: item.message,
      messageColor: "white",
      titleColor: "white",
      progressBarColor: "white",
      timeout: 10000,
      close: true,
      icon: "",
      position: "topRight"
    });
  };

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <audio src={audioSound} ref={audioRef} />
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline="true" className="d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control placeholder="Search" onKeyDown={(e) => keyDown(e)}
            onChange={(e) => setText(e.target.value)} aria-label="Search" value={searchText} />
          <Button variant="" onClick={() => onSearch()}>
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form>

      <Navbar.Collapse className="ms-2">
        <Nav className="navbar-align align-items-center">
          {/* scan qr-code, only display in mobile view*/}
          <ScanCamera />

          {user?.role === 'Admin' && <NavbarDropdown
            header="Recent Alerts"
            footer="Show all Alerts"
            icon={Bell}
            showBadge
            count={lastCount}
          >
            {notifications.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={getIcon(item.ruleType)}
                  title={item.ruleName}
                  description={item.message}
                  time={moment(item.dateTime).format("DD/MM/YY HH:mm:ss")}
                />
              );
            })}
          </NavbarDropdown>}

          <NavbarUser showAdditional={showAdditional} showHasAdmin={showHasAdmin} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
