import { useLocation } from "react-router-dom";
import AssetsEdit from "./AssetsEdit";
import { useEffect, useRef, useState } from "react";
import * as queryString from "query-string";

// a wrapper that can rerender the assets detail page, make sure google map will also repaint when navigate to same url
const InvoicesWrapper = () => {
  const location = useLocation();
  const { id, direct } = queryString.parse(location.search);
  const idRef = useRef(id);
  const [show, setShow] = useState(true)

  useEffect(() => {
    if(id !== idRef.current) {
      setShow(false)
      idRef.current = id
      setTimeout(() => {
        setShow(true)
      }, 200)
    }
  },[id])

  return show ? <AssetsEdit id={id} direct={direct} /> : null
};
export default InvoicesWrapper